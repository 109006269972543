//react
import React, { useReducer, useEffect } from "react";

//Material-UI
import { Grid, Box, Divider, Typography } from "@mui/material";
// import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";

//Date-Fns
import { isValid } from "date-fns";

//Axios
// import axios from 'axios';

//papa-parse
import Papa from "papaparse";

//lodash
import { capitalize, get } from "lodash";

//CSS
import "./deduction.css";

//Local-Imports
import Deductions from "./Deductions/Deductions";
import { PAGINATION } from "utils/constants/pagination";
import { useDispatch, useSelector } from "store";
import {
  fetchDeductionData,
  fetchViewDeductionData,
  resetDeductions,
  reverseDeduction,
  runDeduction,
} from "store/reducers/deductions";
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import {
  convertDateIntoFrontEndFormat,
  dateFormat,
  getBEDateFormat,
} from "utils/date";
import CashDAutocomplete from "components/common/CashDAutocomplete";
import T from "utils/constants/T";
// import DeductionsView from './Deductions/DeductionActions/DeductionsView';
import RunDeduction from "./Deductions/DeductionActions/RunDeduction";
import ReverseDeduction from "./Deductions/DeductionActions/ReverseDeduction";
import {
  fetchDeductionTransactionBusinessUnitList,
  fetchDeductionTransactionPayPeriodList,
  fetchDeductionTransactionSchedulesList,
} from "store/reducers/deductionTransactionFilter";
import { DEDUCTION_STATUS_LIST } from "utils/constants/deductions";
// import { cashdCurrentUser } from 'utils/constants/validations';
import toast from "utils/ToastNotistack";
import { decimalLimiter, exportData } from "utils/CommonMethods";
import { useNavigate } from "react-router";
import authApi from "interceptor/authInterceptor";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const EXPORT_PAGE_SIZE = 10000;

const DeductionPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedCompany } = useSelector(
    (state: any) => state.dashboardSearch
  );
  const selectedCompanyName = get(selectedCompany, "company_name", "");
  const selectedCompanyId = get(selectedCompany, "_id", "");
  const {
    deductionTableList,
    deductionViewList,
    totalDeductionItems,
    totalDeductionPages,
    pageNo,
    pageSize,
  } = useSelector((state: any) => state.deductionData);

  const { scheduleList, businessUnitList } = useSelector(
    (state: any) => state.deductionTransactionFilter
  );
  const { systemPayrollCode } = useSelector(
    (state: any) => state.companyTabData
  );

  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      page: pageNo,
      rowsPerPage: pageSize,
      schedule: null,
      deductionStatus: null,
      businessUnit: null,
      fromDate: null,
      toDate: null,
      openViewDeductionDialog: false,
      openRunDeductionDialog: false,
      openReverseDeductionDialog: false,
      payPeriod: null,
      companyId: "",
      viewPayPeriodId: "",
      deductionRecord: {},
    }
  );

  const {
    page,
    rowsPerPage,
    schedule,
    deductionStatus,
    businessUnit,
    fromDate,
    toDate,
    openViewDeductionDialog,
    openRunDeductionDialog,
    openReverseDeductionDialog,
    payPeriod,
    companyId,
    viewPayPeriodId,
    deductionRecord,
  } = localState;

  //filters arrays
  const payPeriodId = payPeriod ? get(payPeriod, "_id", "") : null;
  const scheduleId = schedule ? get(schedule, "_id", "") : null;
  const businessUnitId = businessUnit ? get(businessUnit, "_id", "") : null;

  //filter listing arrays initial rendering
  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(fetchDeductionTransactionPayPeriodList(selectedCompanyId));
      dispatch(fetchDeductionTransactionSchedulesList(selectedCompanyId));
      if (selectedCompanyName.includes(capitalize(T.HUDSON))) {
        dispatch(fetchDeductionTransactionBusinessUnitList(selectedCompanyId));
      }
    }
  }, [selectedCompanyId]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    companyId: string,
    payPeriodId: string,
    deductionRecord: any
  ) => {
    setAnchorEl(event.currentTarget);
    setLocalState({
      companyId: companyId,
      viewPayPeriodId: payPeriodId,
      deductionRecord,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //View Deduction Modal opening handler
  const handleViewDeductionDailog = () => {
    setLocalState({ openViewDeductionDialog: !openViewDeductionDialog });
  };

  //Deduction run handler
  const handleRunDeductionDailog = () => {
    setLocalState({ openRunDeductionDialog: !openRunDeductionDialog });
  };

  //Reverse reduction handler
  const handleReverseDeductionDailog = () => {
    setLocalState({ openReverseDeductionDialog: !openReverseDeductionDialog });
  };

  //fetch view deduction table Data
  const handleViewDeduction = () => {
    dispatch(
      fetchViewDeductionData(
        INITIAL_PAGE,
        ROWS_PER_PAGE,
        companyId,
        viewPayPeriodId,
        deductionStatus
      )
    );
    setLocalState({ viewPayPeriodId: viewPayPeriodId, companyId: companyId });
    handleViewDeductionDailog();
    handleClose();
  };
  let viewDeductionRows: any = [];
  deductionViewList.map((item: any, index: number) => {
    viewDeductionRows[index] = {
      srNo: index + 1,
      employeeName: get(item, "staff.fullname", ""),
      employeeType: get(item, "staff.fullname", ""),
      totalAmountSent: get(item, "total_deductions_sent", ""),
      totalAmountUnsent: get(item, "total_amount_unsent", ""),
      totalCapitalSent: get(item, "total_amount_sent", ""),
      totalCapitalUnsent: get(item, "total_deductions_unsent", ""),
      totalFeeSent: get(item, "total_fee_sent", ""),
      totalFeeUnsent: get(item, "total_fee_unsent", ""),
    };
  });

  const checkSalaryWag = (salaryWag: any, systemCode: any) => {
    let employeeType;
    if (systemCode === "DEPUTY") {
      employeeType = "Timesheet";
    } else {
      if (salaryWag === 2) {
        employeeType = "Timesheet";
      } else if (salaryWag === 3) {
        employeeType = "Salary & TimeSheet";
      } else {
        employeeType = "Salary";
      }
    }
    return employeeType;
  };

  //Get view deduction data
  const getViewDeductionExportData = (finalData: any) => {
    const filteredList = deductionTableList.filter(
      (item: any) => get(item, "_id", "") === viewPayPeriodId
      );
      console.log(filteredList)
    const company_name: any = get(
      filteredList,
      "[0].company_details.company_name",
      ""
    );
    const statusDeduction: any = get(
      filteredList,
      "[0].is_write_deductions_back_system",
      ""
    );
    const deductionPayPeriod: any = `${dateFormat(
      get(filteredList, "[0].start_date", "")
    )} - ${dateFormat(get(filteredList, "[0].end_date", ""))}`;
    const keyPayScheduleName: any = get(filteredList, "[0].pay_calendar_type","")
    const scheduleName: any = get(
      filteredList,
      "[0].xero_pay_calendar.Name",
      ""
    )
      ? get(filteredList, "[0].xero_pay_calendar.Name", "")
      : get(filteredList, "[0].pay_period_origination.name", "")
    const systemCode: any = get(
      filteredList,
      "[0].company_details.system_code.code",
      ""
    );
    let exportViewDeductionRows: any = [];

    finalData.map((item: any, index: number) => {
      const totalAmountSent: any = get(item, "total_amount_sent", "");
      const amountSent= decimalLimiter(totalAmountSent)
      const totalAMountUnSent: any = get(item, "total_amount_unsent", "");
      const amountUnSent= decimalLimiter(totalAMountUnSent)
      const totalDeductionsSent: any = get(item, "total_deductions_sent", "");
      const deductionSent= decimalLimiter(totalDeductionsSent)
      const totalDeductionsUnSent: any = get(
        item,
        "total_deductions_unsent",
        ""
      );
      const deductionUnSent= decimalLimiter(totalDeductionsUnSent)
      const totalFeeSent: any = get(item, "total_fee_sent", 0);
      const feeSent= decimalLimiter(totalFeeSent)
      const totalFeeUnSent: any = get(item, "total_fee_unsent", 0);
      const feeUnSent= decimalLimiter(totalFeeUnSent)
      const employeeTYpe: any = checkSalaryWag(
        get(item, "salary_wag", ""),
        systemCode
      );
      const businessUnitID: any = get(item, "staff.business_unit_id", "");

      const businessUnitHudsonList: any = businessUnitList.filter(
        (item: any) => get(item, "_id", "") === businessUnitID
      );
      const businessUnitHudson: any = get(
        businessUnitHudsonList,
        "[0].name",
        ""
      );
      const payItemDescription = "CashD RTR";
      const payItemID = "PI000160"
      {
        systemPayrollCode !== "MYOBADVANCED"
          ? (exportViewDeductionRows[index] = {
              srNo: index + 1,
              CompanyName: company_name,
              BusinessUnit: businessUnitHudson ? businessUnitHudson : "N/A",
              FirstName: get(item, "staff.first_name", ""),
              LastName: get(item, "staff.last_name", ""),
              FullName: get(item, "staff.fullname", ""),
              PayrollID: get(item, "staff.system_employee_id", ""),
              CashDID: get(item, "staff.user_id", ""),
              EmployeeType: employeeTYpe ? employeeTYpe : "N/A",
              Schedule: scheduleName ? scheduleName : keyPayScheduleName,
              PayPeriod: deductionPayPeriod,
              Status: statusDeduction ? T.SENT : T.UNSENT,
              Capital: get(item, "total_amount_sent", "")
                ? `$ ${amountSent}`
                : `$ ${amountUnSent}`,
              Fee: get(item, "total_fee_sent", "")
                ? `$ ${feeSent}`
                : `$ ${feeUnSent}`,
              TotalAmount: get(item, "total_deductions_sent", "")
                ? `$ ${deductionSent}`
                : `$ ${deductionUnSent}`,
              NumberOfTransactions: get(item, "total_transactions_unsent", "")
                ? get(item, "total_transactions_unsent", "")
                : get(item, "total_transactions_sent", ""),
            })
          : (exportViewDeductionRows[index] = {
              "Ext. employee ID": get(item, "staff.system_employee_id", ""),
              "Ext. employee name": get(item, "staff.fullname", ""),
              "Ext. pay item ID": payItemID,
              "Ext. pay item description":  payItemDescription,
              "Timesheet date": get(item, "", "") ,
              Amount: get(item, "total_deductions_sent", "")
                ?  deductionSent
                : deductionUnSent,
            });
      }
    });
    return exportViewDeductionRows;
  };

  //Export View deduction CSV
  const handleExportViewDeduction = () => {
    const filteredList = deductionTableList.filter(
      (item: any) => get(item, "_id", "") === viewPayPeriodId
    );
    const statusDeduction: any = get(
      filteredList,
      "[0].is_write_deductions_back_system",
      ""
    );
    const deductionStatus = statusDeduction ? "sent" : "unsent";
    // const { REACT_APP_BACKEND_API_PRIVATE_BASE_URL: baseURL, REACT_APP_X_API_KEY: X_API_KEY } = process.env;
    // const { token } = cashdCurrentUser();
    // const headers = {
    //     headers: {
    //         'x-api-key': X_API_KEY,
    //         token,
    //         'Content-Type': 'application/json'
    //     },
    // }
    authApi
      .get(
        `/v2/api/companies/${companyId}/DeductionFiles/${viewPayPeriodId}?page=${INITIAL_PAGE}&pageSize=${EXPORT_PAGE_SIZE}&deduction_type=${deductionStatus}`
      )
      .then((res) => {
        const finalData = res.data.result;
        const finalExportRow = getViewDeductionExportData(finalData);
        const csvData = Papa.unparse(finalExportRow);
        exportData(csvData, "deduction.csv", "text/csv;charset=utf-8;");
        setLocalState({
          viewPayPeriodId: viewPayPeriodId,
          companyId: companyId,
        });
        handleClose();
      })
      .catch((error) => {
        toast(get(error, "message", ""), { variant: "error" });
      });
  };

  const handleRunDeduction = () => {
    navigate(`/deductions/${companyId}/deductionFiles/${viewPayPeriodId}`, {
      state: { deductionTableList, deduction: deductionRecord },
    });

    handleClose();
  };
  const handleReverseDeduction = () => {
    setLocalState({ viewPayPeriodId: viewPayPeriodId, companyId: companyId });
    handleReverseDeductionDailog();
    handleClose();
  };

  const runSelectedDeductions = () => {
    dispatch(runDeduction(companyId, viewPayPeriodId));
    handleRunDeductionDailog();
  };
  const reverseSelectedDeductions = () => {
    dispatch(reverseDeduction(companyId, viewPayPeriodId));
    handleReverseDeductionDailog();
  };

  useEffect(() => {
    dispatch(resetDeductions());
    dispatch(
      fetchDeductionData(
        INITIAL_PAGE,
        ROWS_PER_PAGE,
        selectedCompanyId,
        fromDate,
        toDate,
        payPeriodId,
        scheduleId,
        businessUnitId,
        deductionStatus
      )
    );

    setLocalState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE });
  }, [selectedCompanyId, payPeriod, schedule, businessUnit, deductionStatus]);

  useEffect(() => {
    if (fromDate && toDate) {
      dispatch(
        fetchDeductionData(
          INITIAL_PAGE,
          ROWS_PER_PAGE,
          selectedCompanyId,
          fromDate,
          toDate,
          payPeriodId,
          scheduleId,
          businessUnitId,
          deductionStatus
        )
      );
      setLocalState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE });
    }
  }, [fromDate, toDate]);

  const getScheduleName = (data: any, systemCode: String) => {
    let scheduleName = { payPeriodName: "", payPeriodId: "" };

    if (systemCode == T.DEPUTY.toUpperCase()) {
      scheduleName.payPeriodName = get(data, "pay_period_origination.name", "");
      scheduleName.payPeriodId = get(data, "pay_period_origination._id", "");
    } else if (systemCode == T.KEYPAY.toUpperCase()) {
      scheduleName.payPeriodName = get(data, "keypay_pay_schedule.name", "");
      scheduleName.payPeriodId = get(data, "keypay_pay_schedule._id", "");
    } else {
      scheduleName.payPeriodName = get(data, "xero_pay_calendar.Name", "");
      scheduleName.payPeriodId = get(data, "xero_pay_calendar._id", "");
    }

    return scheduleName;
  };

  const deductionFinalData: any = [];
  const deductionPayPeriods: any = [];
  deductionTableList.map((data: any, index: number) => {
    const systemCode = get(data, "company_details.system_code.code", "");
    const scheduleData = getScheduleName(data, systemCode);
    deductionPayPeriods.push(
      `${convertDateIntoFrontEndFormat(
        data.start_date
      )} - ${convertDateIntoFrontEndFormat(data.end_date)}`
    );

    deductionFinalData[index] = {
      srNo: index + 1,
      company_name: get(data, "company_details.company_name", ""),
      company_id: get(data, "company_details._id", ""),
      pay_periods: `${dateFormat(data.start_date)} To ${dateFormat(
        data.end_date
      )}`,
      deduction_status: data.is_write_deductions_back_system
        ? T.SENT
        : T.UNSENT,
      withdrawal_status: data.is_prevent_withdrawals ? T.LOCKED : T.OPEN,
      date_time: convertDateIntoFrontEndFormat(
        get(data, "write_deductions_date", "")
      ),
      amount: data.is_write_deductions_back_system
        ? data.total_deductions_sent
        : data.total_deductions_unsent,
      pay_period_id: get(data, "_id", ""),
      isPreventWithdrawals: get(data, "is_prevent_withdrawals", ""),
      payPeriodDetails: scheduleData,
      systemCode: get(data, "company_details.system_code.code", ""),
    };
  });

  // Page change handler
  const handlePageChange = (newPage: number) => {
    setLocalState({ page: newPage });
    dispatch(
      fetchDeductionData(
        newPage,
        rowsPerPage,
        selectedCompanyId,
        fromDate,
        toDate,
        payPeriodId,
        scheduleId,
        businessUnitId,
        deductionStatus
      )
    );
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (event: any) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
    dispatch(
      fetchDeductionData(
        INITIAL_PAGE,
        value,
        selectedCompanyId,
        fromDate,
        toDate,
        payPeriodId,
        scheduleId,
        businessUnitId,
        deductionStatus
      )
    );
  };

  //Date change handler
  const onHandleDateChange = (newValue: any, type: string) => {
    const validDate = newValue ? new Date(newValue) : null;

    setLocalState({
      [type]:
        validDate && isValid(validDate) ? getBEDateFormat(validDate) : null,
    });
  };

  //Autocomplete value change handler
  const onHandleAutoCompleteChange = (type: string, newValue: any) => {
    setLocalState({ [type]: newValue });
  };

  //Handler for clearing the filter
  const handleClearFilters = () => {
    setLocalState({
      fromDate: null,
      toDate: null,
      payPeriod: null,
      schedule: null,
      businessUnit: null,
      deductionStatus: null,
    });
    dispatch(
      fetchDeductionData(
        INITIAL_PAGE,
        rowsPerPage,
        selectedCompanyId,
        null,
        null,
        "",
        "",
        "",
        null
      )
    );
  };

  //Render formatted export file data

  // const formattedExportData = (finalData: any[]) => {
  //     const deductionFinalData: any = [];
  //     finalData.map((data: any, index: number) => {
  //         const systemCode = get(data, "company_details.system_code.code", "");
  //         const scheduleData = getScheduleName(data, systemCode);

  //         deductionFinalData[index] = {
  //             srNo: index + 1,
  //             company_name: get(data, "company_details.company_name", ""),
  //             company_id: get(data, "company_details._id", ""),
  //             pay_periods: `${dateFormat(data.start_date)} To ${dateFormat(data.end_date)}`,
  //             deduction_status: data.is_write_deductions_back_system ? T.SENT : T.UNSENT,
  //             withdrawal_status: data.is_prevent_withdrawals ? T.LOCKED : T.OPEN,
  //             date_time: get(data, "write_deductions_date", "N/A"),
  //             amount: data.is_write_deductions_back_system ? data.total_deductions_sent : data.total_deductions_unsent,
  //             pay_period_id: get(data, "_id", ""),
  //             isPreventWithdrawals: get(data, "is_prevent_withdrawals", ""),
  //             schedule: get(scheduleData, "payPeriodName", "")
  //         }
  //     }
  //     );
  //     return deductionFinalData;
  // }

  //handle CSV Export
  // const handleCSVExport = () => {
  //     // const { REACT_APP_BACKEND_API_PRIVATE_BASE_URL: baseURL, REACT_APP_X_API_KEY: X_API_KEY } = process.env;
  //     const startDate = !fromDate ? "" : fromDate;
  //     const endDate = !toDate ? "" : toDate;
  //     const pay_period_id = payPeriodId ? payPeriodId : "";
  //     const schedule_id = scheduleId ? scheduleId : "";
  //     const business_unit_id = businessUnitId ? businessUnitId : "";
  //     const deduction_status = deductionStatus === T.SENT ? true : deductionStatus === T.UNSENT ? false : "";
  //     // const { token } = cashdCurrentUser();
  //     let pageCount = 0;
  //     // const headers = {
  //     //     headers: {
  //     //         'x-api-key': X_API_KEY,
  //     //         token,
  //     //         'Content-Type': 'application/json'
  //     //     },
  //     // }
  //     authApi.get(`/v2/api/companies/DeductionFiles?company_id=${selectedCompanyId}&page=${INITIAL_PAGE}&pageSize=${EXPORT_PAGE_SIZE}&from_date=${startDate}&to_date=${endDate}&pay_period_id=${pay_period_id}&schedule_id=${schedule_id}&business_unit_id=${business_unit_id}&deduction_status=${deduction_status}`)
  //         .then(res => {
  //             let finalData: any = [];
  //             finalData = res.data.result;
  //             const totalData = res.data.totalItem;
  //             const totalPagesCount = res.data.totalPage;
  //             if (totalData <= EXPORT_PAGE_SIZE) {
  //                 const finalExportData = formattedExportData(res.data.result)
  //                 const csvData = Papa.unparse(finalExportData);
  //                 exportData(csvData, 'data.csv', 'text/csv;charset=utf-8;');
  //             }
  //             else {
  //                 for (let i = 1; i <= totalPagesCount; i++) {
  //                     const pay_period_id = payPeriodId ? payPeriodId : "";
  //                     const schedule_id = scheduleId ? scheduleId : "";
  //                     const business_unit_id = businessUnitId ? businessUnitId : "";
  //                     const startDate = fromDate ? fromDate : "";
  //                     const endDate = toDate ? toDate : "";

  //                     // const headers = {
  //                     //     headers: {
  //                     //         'x-api-key': X_API_KEY,
  //                     //         token,
  //                     //         'Content-Type': 'application/json'
  //                     //     },
  //                     // }
  //                     authApi.get(`/v2/api/companies/DeductionFiles?company_id=${selectedCompanyId}&page=${i}&pageSize=${EXPORT_PAGE_SIZE}&from_date=${startDate}&to_date=${endDate}&pay_period_id=${pay_period_id}&schedule_id=${schedule_id}&business_unit_id=${business_unit_id}&deduction_status=${deduction_status}`)
  //                         .then(res => {
  //                             pageCount++;
  //                             const newFinalData = res.data.result;
  //                             let revisedFinalData = [...finalData, ...newFinalData];
  //                             finalData = revisedFinalData;
  //                             if (i === totalPagesCount && pageCount === totalPagesCount) {
  //                                 const finalExportData = formattedExportData(finalData)
  //                                 const csvData = Papa.unparse(finalExportData);
  //                                 exportData(csvData, 'data.csv', 'text/csv;charset=utf-8;');
  //                             }
  //                         }
  //                         )
  //                         .catch((error) => {
  //                             toast(get(error, "message", ""), { variant: "error" });
  //                         }
  //                         );
  //                 }
  //             }
  //         }
  //         )
  //         .catch((error) => {
  //             toast(get(error, "message", ""), { variant: "error" });
  //         });
  // }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard
            title={
              <>
                <Grid container spacing={1} className="tableSearchBox">
                  <Grid item xs={12} sm={6} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="From Date"
                        inputFormat="DD/MM/YYYY"
                        value={fromDate}
                        onChange={(date) =>
                          onHandleDateChange(date, "fromDate")
                        }
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="To Date"
                        inputFormat="DD/MM/YYYY"
                        value={toDate}
                        onChange={(date) => onHandleDateChange(date, "toDate")}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {systemPayrollCode !== "MYOBADVANCED" &&
                    selectedCompanyId && (
                      <>
                        <Grid item xs={12} sm={6} md={3}>
                          <CashDAutocomplete
                            placeholder={T.SCHEDULE}
                            displayRemoveButton="none"
                            listDetails={scheduleList}
                            value={schedule}
                            getByLabelText={(option: any) =>
                              get(option, "name", "") || get(option, "Name", "")
                            }
                            onHandleChange={(event: any, newValue: any) =>
                              onHandleAutoCompleteChange("schedule", newValue)
                            }
                          />
                        </Grid>

                        {selectedCompanyId &&
                          selectedCompanyName.includes(
                            capitalize(T.HUDSON)
                          ) && (
                            <Grid item xs={12} sm={6} md={2}>
                              <CashDAutocomplete
                                placeholder={T.BUSINESS_UNIT}
                                displayRemoveButton="none"
                                listDetails={businessUnitList}
                                value={businessUnit}
                                getByLabelText={(option: any) =>
                                  get(option, "name", "")
                                }
                                onHandleChange={(event: any, newValue: any) =>
                                  onHandleAutoCompleteChange(
                                    "businessUnit",
                                    newValue
                                  )
                                }
                              />
                            </Grid>
                          )}
                      </>
                    )}
                  <Grid item xs={12} sm={6} md={2}>
                    <CashDAutocomplete
                      placeholder={T.STATUS}
                      displayRemoveButton="none"
                      listDetails={DEDUCTION_STATUS_LIST}
                      value={deductionStatus}
                      getByLabelText={(option: any) => option}
                      onHandleChange={(event: any, newValue: any) =>
                        onHandleAutoCompleteChange("deductionStatus", newValue)
                      }
                    />
                  </Grid>
                  <Grid item xs={3} sm={3} md={1}>
                    <Box
                      sx={{
                        display: "flex",
                        "& h6": {
                          m: 1,
                        },
                        "& hr": {
                          mx: 0.2,
                          my: 0.1,
                        },
                      }}
                    >
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <Typography
                        variant="h6"
                        className="reset"
                        onClick={handleClearFilters}
                      >
                        {T.RESET}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </>
            }
            content={false}
            // secondary={
            //     <Box sx={{ display: "block", pl: 4 }}>
            //         <Button startIcon={<FileDownloadOutlinedIcon />} variant='contained' onClick={handleCSVExport}>
            //             {T.EXPORT}
            //         </Button>
            //         {/* <Box onClick={handleExportFetch}>
            //             <CSVExport data={exportData} filename={'transactions.csv'} />
            //         </Box> */}
            //     </Box>
            // }
          >
            <ScrollX>
              <Deductions
                allTableRows={deductionFinalData}
                totalTableRowsCount={totalDeductionItems}
                totalPageCount={totalDeductionPages}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                handleClick={handleClick}
                handleClose={handleClose}
                handleViewDeduction={handleViewDeduction}
                open={open}
                anchorEl={anchorEl}
                handleExportViewDeduction={handleExportViewDeduction}
                deductionViewList={viewDeductionRows}
                onRowsPerPageChange={handleRowsPerPageChange}
                handleRunDeduction={handleRunDeduction}
                handleReverseDeduction={handleReverseDeduction}
              />
            </ScrollX>
          </MainCard>
        </Grid>
      </Grid>
      <RunDeduction
        openRunDeductionDialog={openRunDeductionDialog}
        handleRunDeductionDailog={handleRunDeductionDailog}
        runSelectedDeductions={runSelectedDeductions}
      />
      <ReverseDeduction
        openReverseDeductionDialog={openReverseDeductionDialog}
        handleReverseDeductionDailog={handleReverseDeductionDailog}
        reverseSelectedDeductions={reverseSelectedDeductions}
      />
    </>
  );
};

export default DeductionPage;
