//react
import { useReducer, useEffect } from 'react';

//Material_UI
import { Grid, Button, InputBase, Box, Divider, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';

//Date-Fns
import { format, isValid } from 'date-fns';

//Lodash
import { capitalize, get } from 'lodash';

//Axios
import axios from 'axios';

//papa-parse
import Papa from 'papaparse';

//CSS
import './transaction.css'


//Local-Imports
import Transactions from './Transactions/Transactions';
import { PAGINATION } from 'utils/constants/pagination';
import { useDispatch, useSelector } from 'store';
import { fetchTransactionData } from 'store/reducers/transactionData';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import { dateFormat, getBEDateFormat } from 'utils/date';
import T from 'utils/constants/T';
import toast from "utils/ToastNotistack";
import CashDAutocomplete from 'components/common/CashDAutocomplete';
import { fetchDeductionTransactionBusinessUnitList, fetchDeductionTransactionPayPeriodList, fetchDeductionTransactionSchedulesList } from 'store/reducers/deductionTransactionFilter';
import { cashdCurrentUser } from 'utils/constants/validations';
import { decimalLimiter, exportData } from 'utils/CommonMethods';
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const EXPORT_PAGE_SIZE = 10000;
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  // width: '100%',
  border: "1px solid #d9d9d9",
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create('width'),
    width: '100%'
  },
}));
const TransactionPage = () => {
  const dispatch = useDispatch();
  const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
  const selectedCompanyName = get(selectedCompany, "company_name", "");
  const selectedCompanyId = get(selectedCompany, "_id", "");
  const { transactionTableList, totalTransactionItems, totalTransactionPages, loader, pageNo, pageSize } = useSelector((state: any) => state.transactionData);
  const { payPeriodList, scheduleList, businessUnitList } = useSelector((state: any) => state.deductionTransactionFilter);


  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      page: pageNo,
      rowsPerPage: pageSize,
      fromDate: null,
      toDate: null,
      schedule: null,
      payPeriod: null,
      employee: "",
      payrollId: "",
      businessUnit: null,
      isLoading: false,
      // showMoreFilters: false
    }
  );

  const {
    page,
    rowsPerPage,
    fromDate,
    toDate,
    schedule,
    payPeriod,
    employee,
    payrollId,
    businessUnit,
    isLoading,
    // showMoreFilters
  } = localState;


  //filters arrays
  const payPeriodId = payPeriod ? get(payPeriod, "_id", "") : null;
  const scheduleId = schedule ? get(schedule, "_id", "") : null;
  const businessUnitId = businessUnit ? get(businessUnit, "_id", "") : null;

  //filter listing arrays initial rendering
  useEffect(() => {
    if (selectedCompanyId) {
      dispatch(fetchDeductionTransactionPayPeriodList(selectedCompanyId))
      dispatch(fetchDeductionTransactionSchedulesList(selectedCompanyId))
      if (selectedCompanyName.includes(capitalize(T.HUDSON))) {
        dispatch(fetchDeductionTransactionBusinessUnitList(selectedCompanyId))
      }
    }
  }, [selectedCompanyId])


  //table data render on filter values change and initial render
  useEffect(() => {
    dispatch(fetchTransactionData(INITIAL_PAGE, ROWS_PER_PAGE, fromDate, toDate, selectedCompanyId, payPeriodId, scheduleId, businessUnitId, payrollId, employee));
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE });
  }, [selectedCompanyId, payrollId, employee, payPeriod, schedule, businessUnit]);


  //table data render on date filter selection
  useEffect(() => {
    if (fromDate && toDate) {
      dispatch(fetchTransactionData(INITIAL_PAGE, ROWS_PER_PAGE, fromDate, toDate, selectedCompanyId, payPeriodId, scheduleId, businessUnitId, payrollId, employee));
      setLocalState({ page: INITIAL_PAGE, rowsPerPage: ROWS_PER_PAGE });
    }
  }, [fromDate, toDate])


  //Get schedule name from API
  const getScheduleName: any = (data: any, systemCode: String) => {
    let scheduleName = { payPeriodName: "", payPeriodId: "" };
    console.log("systemCode", systemCode)
    if (systemCode === T.DEPUTY.toUpperCase()) {
      scheduleName.payPeriodName = get(data, "pay_deductions.pay_period_originations[0].name", "");
      scheduleName.payPeriodId = get(data, "pay_deductions.pay_period_originations[0]._id", "");
    } else if (systemCode === T.KEYPAY.toUpperCase()) {
      scheduleName.payPeriodName = get(data, "pay_deductions.keypay_pay_schedules[0].name", "");
      scheduleName.payPeriodId = get(data, "pay_deductions.keypay_pay_schedules[0]._id", "");
    } else {
      scheduleName.payPeriodName = get(data, "pay_deductions.xero_pay_calendars[0].Name", "");
      scheduleName.payPeriodId = get(data, "pay_deductions.xero_pay_calendars[0]._id", "");
    }
    return scheduleName;
  }

  const transactionFinalData: any = [];
  transactionTableList.map((data: any, index: number) => {
    const systemCode = get(data, "company.systems[0].code", "");


    const scheduleData = getScheduleName(data, systemCode);
    const payPeriods = `${dateFormat(get(data, "pay_deductions.pay_periods[0].start_date", null))} ${T.TO} ${dateFormat(get(data, "pay_deductions.pay_periods[0].end_date", null))}`
    const systemPayrollId = data.staff.system_employee_id;
    transactionFinalData[index] = {
      srNo: index + 1,
      company_name: data?.company?.company_name,
      employee_name: data.staff.fullname,
      pay_periods: payPeriods,
      schedule_details: scheduleData,
      Date: data.pay_deductions.date,
      transaction_id: data.pay_deductions.transaction_id,
      system_payroll_id: systemPayrollId,
      debit: decimalLimiter(data.total_deduction),
      fee: decimalLimiter(data.fee_amount),
      status: data.pay_deductions.status
    }
  }
  );



  // Page change handler
  const handlePageChange = (newPage: number) => {
    setLocalState({ page: newPage });
    dispatch(fetchTransactionData(newPage, rowsPerPage, fromDate, toDate, selectedCompanyId, payPeriodId, scheduleId, businessUnitId, payrollId, employee));
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };


  // Rows per page change handler
  const handleRowsPerPageChange = (event: any) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
    dispatch(fetchTransactionData(page, value, fromDate, toDate, selectedCompanyId, payPeriodId, scheduleId, businessUnitId, payrollId, employee));
  };


  //Date filter handler
  const onHandleDateChange = (newValue: any, type: string) => {
    const validDate = newValue ? new Date(newValue) : null;
    setLocalState({
      [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null
    });
  };

  //Handler for clearing the filter
  const handleClearFilters: any = () => {
    setLocalState({ fromDate: null, toDate: null, payPeriod: null, schedule: null, businessUnit: null, payrollId: "", employee: "" });
    dispatch(fetchTransactionData(INITIAL_PAGE, rowsPerPage, null, null, selectedCompanyId, null, null, null, "", ""));
  }


  //Autocomplete value change handler
  const onHandleAutoCompleteChange = (type: string, newValue: any) => {
    setLocalState({ [type]: newValue });
  };


  //On Change Handler for search filters
  const onHandleChange = (e: any) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value })
  }


  //Render formatted export file data

  const formattedExportData = (finalData: any[]) => {
    const transactionExportFinalData: any = [];
    finalData.map((data: any, index: number) => {
      const systemCode = get(data, "company.systems[0].code", "");

      const scheduleData = getScheduleName(data, systemCode);
      const payPeriods = `${dateFormat(get(data, "pay_deductions.pay_periods[0].start_date", null))} ${T.TO.toLowerCase()} ${dateFormat(get(data, "pay_deductions.pay_periods[0].end_date", null))}`
      const systemPayrollId = data.staff.system_employee_id;
      const capitalAmount = data.total_deduction - data.fee_amount;
      const deductionDate = new Date(data.pay_deductions.date);
      const date =  format(deductionDate, 'dd-MM-yyyy h:mm:ss a');
      transactionExportFinalData[index] = {
        srNo: index + 1,
        company_name: data.company.company_name,
        employee_name: data.staff.fullname,
        pay_periods: payPeriods,
        schedule_details: get(scheduleData, "payPeriodName", ""),
        date: date,
        transaction_id: data.pay_deductions.transaction_id,
        system_payroll_id: systemPayrollId,
        capital: `$${capitalAmount}`,
        fee: `$${data.fee_amount}`,
        total: `$${data.total_deduction}` ,
        status: get(data, "pay_deductions.status", T.PENDING)
      }
    }
    );
    return transactionExportFinalData;
  }
  //handle CSV Export
  const handleCSVExport = () => {
    setLocalState({ isLoading: true })
    const { token } = cashdCurrentUser()
    const { REACT_APP_BACKEND_API_PRIVATE_BASE_URL: baseURL, REACT_APP_X_API_KEY: X_API_KEY } = process.env;
    const pay_period_id = payPeriodId ? payPeriodId : "";
    const schedule_id = scheduleId ? scheduleId : "";
    const business_unit_id = businessUnitId ? businessUnitId : "";
    const from_date = fromDate ? fromDate : "";
    const to_date = toDate ? toDate : "";
    let pageCount = 0;
    const headers = {
      headers: {
        'x-api-key': X_API_KEY,
        Authorization: `JWT ${token}`,
        'Content-Type': 'application/json'
      },
    }
    axios.get(`${baseURL}/api/timesheets/getAllDeductions?page=${INITIAL_PAGE}&pageSize=${EXPORT_PAGE_SIZE}&company_id=${selectedCompanyId}&pay_period_id=${pay_period_id}&schedule_id=${schedule_id}&business_unit_id=${business_unit_id}&startDate=${from_date}&endDate=${to_date}&mode=0&isAll=0&payroll_id=${payrollId}&employee_name=${employee}`, headers)
      .then(res => {
        let finalData: any = [];
        finalData = res.data.result;
        const totalData = res.data.totalItem;
        const totalPagesCount = res.data.totalPage;
        if (totalData <= EXPORT_PAGE_SIZE) {
          const finalExportData = formattedExportData(res.data.result)
          const csvData = Papa.unparse(finalExportData);
          exportData(csvData, 'data.csv', 'text/csv;charset=utf-8;');
          setLocalState({ isLoading: false })
        }
        else {
          for (let i = 1; i <= totalPagesCount; i++) {
            const pay_period_id = payPeriodId ? payPeriodId : "";
            const schedule_id = scheduleId ? scheduleId : "";
            const business_unit_id = businessUnitId ? businessUnitId : "";
            const from_date = fromDate ? fromDate : "";
            const to_date = toDate ? toDate : "";
            const headers = {
              headers: {
                'x-api-key': X_API_KEY,
                Authorization: `JWT ${token}`,
                'Content-Type': 'application/json'
              },
            }
            axios.get(`${baseURL}/api/timesheets/getAllDeductions?page=${i}&pageSize=${EXPORT_PAGE_SIZE}&company_id=${selectedCompanyId}&pay_period_id=${pay_period_id}&schedule_id=${schedule_id}&business_unit_id=${business_unit_id}&startDate=${from_date}&endDate=${to_date}&mode=0&isAll=0&payroll_id=${payrollId}&employee_name=${employee}`, headers)
              .then(res => {
                pageCount++
                const newFinalData = res.data.result;
                let revisedFinalData = [...finalData, ...newFinalData];
                finalData = revisedFinalData;
                if (i === totalPagesCount && pageCount === totalPagesCount) {
                  const finalExportData = formattedExportData(finalData)
                  const csvData = Papa.unparse(finalExportData);
                  exportData(csvData, 'data.csv', 'text/csv;charset=utf-8;');
                  setLocalState({ isLoading: false })
                }
              }
              )
              .catch((error) => {
                toast(get(error, "message", ""), { variant: "error" });
                setLocalState({ isLoading: false })
              }
              );
          }
        }
      }
      )
      .catch((error) => {
        toast(get(error, "message", ""), { variant: "error" });
        setLocalState({ isLoading: false })
      });
  }


  //More filters show hide handler
  //  const handleShowFilter=()=>{
  //   setLocalState({showMoreFilters:!showMoreFilters})
  //  }

  return (
    <>
      {(loader || isLoading) && <Loader />}
      <MainCard
        title={
          <>
            <Grid container spacing={1} className="tableSearchBox">
              <Grid item xs={12} sm={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="From Date"
                    inputFormat="DD/MM/YYYY"
                    value={fromDate}
                    onChange={(date) =>
                      onHandleDateChange(date, "fromDate")
                    }
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="To Date"
                    inputFormat="DD/MM/YYYY"
                    value={toDate}
                    onChange={(date) => onHandleDateChange(date, "toDate")}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              {selectedCompanyId && (
                <Grid item xs={12} sm={6} md={3}>
                  <CashDAutocomplete
                    placeholder={T.PAY_PERIOD}
                    displayRemoveButton="none"
                    listDetails={payPeriodList}
                    value={payPeriod}
                    getByLabelText={(option: any) =>
                      `${dateFormat(
                        option.start_date
                      )} To ${dateFormat(option.end_date)}`
                    }
                    onHandleChange={(event: any, newValue: any) =>
                      onHandleAutoCompleteChange("payPeriod", newValue)
                    }
                  />
                </Grid>
              )}
              {selectedCompanyId && (
                <Grid item xs={12} sm={6} md={3}>
                  <CashDAutocomplete
                    placeholder={T.SCHEDULE}
                    displayRemoveButton="none"
                    listDetails={scheduleList}
                    value={schedule}
                    getByLabelText={(option: any) =>
                      get(option, "name", "") || get(option, "Name", "")
                    }
                    onHandleChange={(event: any, newValue: any) =>
                      onHandleAutoCompleteChange("schedule", newValue)
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={2}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={T.EMPLOYEE}
                    name="employee"
                    value={employee}
                    inputProps={{ "aria-label": "search" }}
                    onChange={onHandleChange}
                  />
                </Search>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={T.PAYROLL_ID}
                    name="payrollId"
                    value={payrollId}
                    inputProps={{ "aria-label": "search" }}
                    onChange={onHandleChange}
                  />
                </Search>
              </Grid>
              {selectedCompanyId &&
                selectedCompanyName.includes(capitalize(T.HUDSON)) && (
                  <Grid item xs={12} sm={6} md={2}>
                    <CashDAutocomplete
                      placeholder={T.BUSINESS_UNIT}
                      displayRemoveButton="none"
                      listDetails={businessUnitList}
                      value={businessUnit}
                      getByLabelText={(option: any) =>
                        get(option, "name", "")
                      }
                      onHandleChange={(event: any, newValue: any) =>
                        onHandleAutoCompleteChange("businessUnit", newValue)
                      }
                    />
                  </Grid>
                )}
              <Grid item xs={3} sm={3} md={1}>
                <Box
                  sx={{
                    display: 'flex',
                    "& h6": {
                      m: 1,
                    },
                    "& hr": {
                      mx: 0.2,
                      my: 0.1,
                    },
                  }}
                >
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                  />
                  <Typography
                    variant="h6"
                    className="reset"
                    onClick={handleClearFilters}
                  >
                    {T.RESET}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        }
        content={false}
        secondary={
          <>
            {
              <Box sx={{ display: "block", pl: 4 }}>
                <Button startIcon={<FileDownloadOutlinedIcon />} variant='contained' onClick={handleCSVExport}>
                  {T.EXPORT}
                </Button>
              </Box>
            }
          </>
        }
      >
        <ScrollX>
          <Transactions
            allTableRows={transactionFinalData}
            totalTableRowsCount={totalTransactionItems}
            totalPageCount={totalTransactionPages}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </ScrollX>
      </MainCard>
    </>
  );
};

export default TransactionPage;