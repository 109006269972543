//react
import { useEffect } from 'react';

//Material-UI
import { Grid, Button, Divider, Typography } from '@mui/material';
// import CachedIcon from '@mui/icons-material/Cached';

//local imports
import MainCard from 'components/MainCard';
import CashDCard from 'components/common/CashDCard';
import { useNavigate } from 'react-router';
import T from 'utils/constants/T';
import { StyledBodyTypography, StyledTitleTypography } from '../CompanyTab/CompanyInfo';
import {
    //  SyncPaymentPartnerTransactions, 
     fetchBankingSettingsTabData } from 'store/reducers/companyBankingSettings';
import { SECRET_ENCRYPTION_KEY } from 'utils/ServerConstants';
// import { convertDateIntoFrontEndFormat } from 'utils/date';
import ViewBankingDetails from './ViewBankingDetails';

//react-redux
import { useSelector, useDispatch } from 'react-redux';

//Lodash
import { get } from 'lodash';

//CSS
import '../../companyDetails.css';
import './banking.css';

//crypto-js
var CryptoJS = require("crypto-js");


// import { convertDateIntoBankingFEFormat } from 'utils/date';

export const decryptBankingData = (text: any) => {
    const bytes = CryptoJS.AES.decrypt(text, "NkySbgsHatk2KDUA");
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
};

export const getFileNameFromUrl = (url: string) => {
    const splitUrl = url.split("/");
    const fileName = splitUrl[splitUrl.length - 1]
    return fileName;
}

const ViewBankingSettings = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { selectedCompany } = useSelector((state: any) => state.dashboardSearch);
    const selectedCompanyId = get(selectedCompany, "_id", "");
    // const { companyName, bankingEnvironment, bankingValidateBankAccount } = useSelector((state: any) => state.companyTabData);
    const { companyName, bankingEnvironment } = useSelector((state: any) => state.companyTabData);
    // const { paymentPartner, accountNumber, nameOfLender, credit, fundingType, debit, nameOfBank, balance, accountName, dateAndTime, bsb, interestRate } = useSelector((state: any) => state.bankingSettingsTabData);
    const { paymentPartnerList, existingPaymentPartner, accountNumber, lenderId, fundingType, nameOfBank, balance, accountName, bsb, interestRate } = useSelector((state: any) => state.bankingSettingsTabData);
    // const { paymentPartner } = useSelector((state: any) => state.bankingSettingsTabData);
    const existingPaymentPartnerObj = paymentPartnerList?.find((res: any) => res?.code === existingPaymentPartner)
    const paymentPartner = get(existingPaymentPartnerObj, "code", "");
    const BANK_ENV = bankingEnvironment ? T.LIVE : T.TEST;
    // const VALIDATE_BANK_ACCOUNT=bankingValidateBankAccount?T.ON:T.OFF;
    // const formatted_date_time=dateAndTime?convertDateIntoBankingFEFormat(dateAndTime):"";

    //Decryption of encrypted banking data from backend
    const decryptBankingData = (text: any) => {
        const decryptedData = CryptoJS.AES.decrypt(text, SECRET_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8);

        return JSON.parse(JSON.stringify(decryptedData));
    };

    //get view screen data on 1st render
    useEffect(() => {
        dispatch(fetchBankingSettingsTabData(selectedCompanyId));
    }, [lenderId])

    //handle navigation on edit button click
    const handleEdit = () => {
        navigate('/companyDetails/bankingSettings/edit');
    };


    //handle click on Sync Payment Partner Transaction button
    // const handlePaymentPartnerTransactionSync = () => {
    //     dispatch(SyncPaymentPartnerTransactions(selectedCompanyId));
    // }

    //view banking read only data collection
    const renderViewBankingData = () => ({
        accountName,
        fundingType,
        nameOfBank,
        bsb: decryptBankingData(bsb),
        accountNumber: decryptBankingData(accountNumber),
        balance,
        interestRate
    })

    return (
        <MainCard
            title={companyName}
            secondary={
                <>
                    {/* <Button
                        variant="outlined"
                        className='main-card-button'
                        startIcon={<CachedIcon />}
                        onClick={handlePaymentPartnerTransactionSync}
                    >
                        {T.SYNC_PAYMENT_PARTNER_TRANSACTIONS}
                    </Button>
                    &nbsp;&nbsp;&nbsp; */}
                    <Button
                        variant="contained"
                        className='main-card-button'
                        onClick={handleEdit}
                    >
                        {T.EDIT}
                    </Button>
                </>
            }
        >
            <CashDCard borderRadius='20px'>
                <Grid container rowSpacing={1}>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.ENVIRONMENT}</StyledTitleTypography>
                        <StyledBodyTypography>{BANK_ENV}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledTitleTypography>{T.PAYMENT_PARTNER}</StyledTitleTypography>
                        <StyledBodyTypography>{paymentPartner}</StyledBodyTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </CashDCard>
            {
                lenderId ?
                    <CashDCard borderRadius='20px'>
                        <ViewBankingDetails
                            {...renderViewBankingData()}
                        />
                    </CashDCard>
                    :
                    <CashDCard borderRadius='20px'>
                        <Typography className='lender-heading'>
                            {T.LENDER}
                        </Typography>
                        <Typography className='lender-typo'>
                            {T.DOES_NOT_EXIST}
                        </Typography>
                    </CashDCard>

            }


        </MainCard>
    );
};

export default ViewBankingSettings;