import { useReducer, useEffect } from "react";
import { Grid, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import T from "utils/constants/T";
import {
  StyledBodyTypography,
  StyledTitleTypography,
} from "../../../CompanyDetailsScreen/CompanyDetails/CompanyTab/CompanyInfo";
import CashDCard from "components/common/CashDCard";
import { useSelector } from "store";
import MainCard from "components/MainCard";
import CashDDialog from "components/common/CashDDialog";
import CashDAutocomplete from "components/common/CashDAutocomplete";
import CashDTextField from "components/common/CashDTextfield";
import { TRANSACTION_FEE_DEDUCTION_TYPES } from "utils/constants/companyDetails";
import { useDispatch } from "react-redux";
import {
  getUserByUserID,
  saveEmployeeSetting,
} from "store/reducers/getEmployeeData";
import { useNavigate, useParams } from "react-router";

const EditEmployeeSettings = () => {
  const dispatch = useDispatch();
  const { employeeID } = useParams();
  const navigate = useNavigate();
  const getUserDataByUSerID = useSelector((state) => state.getEmployeeData);
  const [localState, setLocalState] = useReducer(
    (prevState: any, newState: any) => ({ ...prevState, ...newState }),
    {
      maximumWithdrawPer: 0,
      maxWithdrawalMoney: 0,
      minimumWithdrawal: 0,
      currencyType: T.DOLLAR,
      limitMoney: false,
      opneDeleteLogoConfirmationModal: false,
    }
  );
  const {
    maximumWithdrawPer,
    maxWithdrawalMoney,
    minimumWithdrawal,
    currencyType,
    // limitMoney,
    opneDeleteLogoConfirmationModal,
  } = localState;
  useEffect(() => {
    setLocalState({
      limitMoney: getUserDataByUSerID.maxType ? true : false,
      maximumWithdrawPer: getUserDataByUSerID.maxWithdrawalPercent,
      maxWithdrawalMoney: getUserDataByUSerID.maxWithdrawalMoney,
      minimumWithdrawal: getUserDataByUSerID.minWithdrawal,
      currencyType: getUserDataByUSerID.maxType ? T.PERCENTAGE : T.DOLLAR,
    });
  }, [getUserDataByUSerID]);

  const payload = {
    is_limit_money_max_wallet: currencyType === T.PERCENTAGE ? true : false,
    min_withdrawal: minimumWithdrawal,
    limit_allowable_percent_drawdown: maximumWithdrawPer,
    limit_money: maxWithdrawalMoney,
  };

  const staffID = getUserDataByUSerID.staffID;
  const handleSave = async () => {
    const res: any = await dispatch(saveEmployeeSetting(staffID, payload));
    if (res.code === 200) {
      await dispatch(getUserByUserID(employeeID));
      navigate(`/employees/employeeDetails/${employeeID}/settings/view`);
    }
  };
  const handleCancel = () => {
    handleCancelConfirmationModal();
  };

  const handleCancelConfirmationModal: any = () => {
    setLocalState({
      maximumWithdrawPer: getUserDataByUSerID.maxWithdrawalPercent,
      maxWithdrawalMoney: getUserDataByUSerID.maxWithdrawalMoney,

      minimumWithdrawal: getUserDataByUSerID.minWithdrawal,
      limitMoney: getUserDataByUSerID.maxType,
      opneDeleteLogoConfirmationModal: !opneDeleteLogoConfirmationModal,
    });
  };

  const handleCancelConfirmation = async () => {
    setLocalState({
      opneDeleteLogoConfirmationModal: !opneDeleteLogoConfirmationModal,
    });
  };
  const onHandleChange = (e: any) => {
    const { value } = e.target;
    if (currencyType === T.PERCENTAGE) {
      setLocalState({
        maximumWithdrawPer: value,
        maxWithdrawalMoney: getUserDataByUSerID.maxWithdrawalMoney,
      });
    } else {
      setLocalState({
        maximumWithdrawPer: getUserDataByUSerID.maxWithdrawalPercent,
        maxWithdrawalMoney: value,
      });
    }
  };

  const onHandleChangeMin = async (e: any) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value });
  }

  const onHandleAutoCompleteChange = (type: string, newValue: any) => {
    const newLimitMoney = newValue !== "%" ? true : false;
    setLocalState({ [type]: newValue, limitMoney: newLimitMoney });
  };

  return (
    <MainCard
      title="          "
      secondary={
        <>
          <Button
            variant="outlined"
            onClick={handleCancel}
            className="main-card-button"
          >
            {T.CANCEL}
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            // className='main-card-button'
            style={{ marginLeft: "8px" }}
          >
            {T.SAVE}
          </Button>
        </>
      }
    >
      <Grid item xs={12}>
        <CashDCard borderRadius="20px">
          <Grid container rowSpacing={2}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4}>
                  <StyledTitleTypography>
                    {T.EMPLOYEE_NAME}
                  </StyledTitleTypography>
                </Grid>
                <Grid item xs={8}>
                  <StyledBodyTypography>
                    {getUserDataByUSerID.employeeName}
                  </StyledBodyTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4}>
                  <StyledTitleTypography>
                    {T.EMPLOYEE_EMAIL}
                  </StyledTitleTypography>
                </Grid>
                <Grid item xs={8}>
                  <StyledBodyTypography>
                    {getUserDataByUSerID.email}
                  </StyledBodyTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="divider" item xs={12}></Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4}>
                  <StyledTitleTypography>
                    {T.MAXIMUM_WITHDRAWAL}
                  </StyledTitleTypography>
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <CashDAutocomplete
                        placeholder=""
                        fullWidth
                        displayRemoveButton="none"
                        listDetails={TRANSACTION_FEE_DEDUCTION_TYPES}
                        value={currencyType}
                        getByLabelText={(option: any) => option}
                        onHandleChange={(event: any, newValue: any) =>
                          onHandleAutoCompleteChange("currencyType", newValue)
                        }
                      />
                    </Grid>
                    {currencyType === T.PERCENTAGE ? (
                      <Grid item xs={7.5}>
                        <CashDTextField
                          fullWidth
                          placeholder={T.PERCENTAGE}
                          autoComplete="on"
                          type="number"
                          size="medium"
                          variant="outlined"
                          name="maximumWithdrawPer"
                          value={maximumWithdrawPer}
                          onChange={onHandleChange}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={7.5}>
                        <CashDTextField
                          fullWidth
                          placeholder={T.MAXIMUM_WITHDRAWAL_MONEY}
                          autoComplete="on"
                          type="number"
                          size="medium"
                          variant="outlined"
                          name="maxWithdrawalMoney"
                          value={maxWithdrawalMoney}
                          onChange={onHandleChange}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={4}>
                  <StyledTitleTypography>
                    {`${T.MINIMUM_WITHDRAWAL} (${T.DOLLAR})`}
                  </StyledTitleTypography>
                </Grid>
                <Grid item xs={6}>
                  <CashDTextField
                    fullWidth
                    placeholder={T.MINIMUM_WITHDRAWAL}
                    autoComplete="on"
                    type="number"
                    size="medium"
                    variant="outlined"
                    name="minimumWithdrawal"
                    value={minimumWithdrawal}
                    onChange={onHandleChangeMin}
                  />
                </Grid>
                {/* <Grid item xs={2}/> */}
              </Grid>
            </Grid>
          </Grid>
        </CashDCard>
        <CashDDialog
          open={opneDeleteLogoConfirmationModal}
          handleClose={handleCancelConfirmationModal}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              width: 450,
              textAlign: "center",
            }}
          >
            {`${T.ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THE_CHANGES}`}
          </Typography>
          <Box className="confirmation_modal">
            <Button variant="outlined" onClick={handleCancelConfirmationModal}>
              {T.NO}
            </Button>
            <Button variant="contained" onClick={handleCancelConfirmation}>
              {T.YES}
            </Button>
          </Box>
        </CashDDialog>
      </Grid>
    </MainCard>
  );
};

export default EditEmployeeSettings;
